.header {
    border: none !important;
    min-height: auto !important;
    padding: 8px 16px !important;
}

.header :global(.ant-typography) {
    font-size: 12px;
    font-weight: 400;
}

.body {
    padding: 16px !important;
    font-size: 14px;
    font-weight: 600;
}
