.map {
    height: 680px;
}

.fields {
    width: 50%;
    max-width: 600px;
    flex-grow: 0;
    flex-shrink: 0;
}

.mapContainer {
    flex-grow: 1;
}
