.row {
    padding: 10px 20px;
    align-items: center;
    border-radius: 5px;
}

.headerCol {
    width: 120px;
    text-align: center;
}

.defect:hover {
    background-color: #fafafa;
}

.indent {
    padding-left: 40px;
}
