.layout {
    background: radial-gradient(50% 50% at 50% 50%, #a3c9ef 15%, #0c87f5 100%);
    background-size: cover;
}

.layoutInner {
    height: 100vh;
}

.container {
    max-width: 408px;
    margin: auto;
}

.title {
    text-align: center;
    color: #626262;
    font-size: 30px;
    letter-spacing: -0.04em;
}

.imageContainer {
    margin-bottom: 12px;
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    padding: 8px;
    background-color: white;
}

.submit {
    margin-top: 12px;
}
