.row {
    margin-bottom: 16px;
    flex-grow: 1;
}
.quarter {
    flex-grow: 0;
    flex-shrink: 0;
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.button {
    margin-bottom: 16px;
}
