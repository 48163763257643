.logo,
.logo * {
    transition: all 0.2s;
}

.collapsed {
    width: 104px;
}

.expanded {
    width: 152px;
}

.collapsed :global(.cls-7) {
    transform: scale(0);
    transform-origin: center;
}

.expanded :global(.cls-7) {
    transform: scale(1);
    transform-origin: center;
}
