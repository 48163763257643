.card {
    height: 100%;
}

.loading :global(.ant-card-body) {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardTitle {
    display: flex;
    align-items: center;
    gap: 8px;
}

.cardTitleText {
    margin-left: 0.5rem;
    font-weight: 400;
}
