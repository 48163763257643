&:root {
    // font-family: 'Roboto', sans-serif !important;
    font-family: 'Inter Variable', sans-serif !important;
}

body {
    // font-family: 'Roboto', sans-serif !important;
    font-family: 'Inter Variable', sans-serif !important;
}

.table-with-header-cell-nowrap th {
    white-space: nowrap;
}

.g2-tooltip {
    font-family: inherit !important;
}

/**
 * When deleting projects undo notification appears in the top right corner.
 * Undo button in that notification is partially covered by invisible close button.
 * This is a hacky way to hide that invisible close button.
 */
.ant-notification-notice-close:empty {
    display: none !important;
}
