.fields :global(.ant-checkbox-wrapper) {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px;
}

.fields :global(.ant-checkbox-wrapper) > span:nth-child(1) {
    flex-grow: 0;
    order: 2;
    margin-left: 16px;
    margin-right: 16px;
}

.fields :global(.ant-checkbox-wrapper) > span:nth-child(2) {
    flex-grow: 1;
    order: 1;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 0;
    padding-right: 0;
}

.fields :global(.ant-checkbox-wrapper):hover {
    background-color: #fafafa;
}
