.form :global(.ant-card-actions) {
    display: none;
}

.steps {
    margin-bottom: 40px;
}

.actions {
    margin-left: -24px;
    width: calc(100% + 48px);
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    border-top: 1px solid #f0f0f0;
}
