.card {
    height: 100%;
}

.cardTitle {
    display: flex;
    align-items: center;
    gap: 8px;
}

.cardTitleText {
    margin-left: 0.5rem;
    font-weight: 400;
}
