.card {
    height: 100%;
}

.list {
    display: flex;
    gap: 8px;
    flex-grow: 1;
    justify-content: stretch;
}

.list > * {
    flex-grow: 1;
}

.list td {
    border: none !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    border-radius: 5px;
}
