/* .input-number-handler-invisible .ant-input-number-handler-wrap {
    display: none;
} */

/** @todo Use tailwind.css instead of these classes */

.cursor-pointer {
    cursor: pointer;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.hidden {
    display: none;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-flex {
    display: flex;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-grow-0 {
    flex-grow: 0;
}

.flex-shrink-1 {
    flex-shrink: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-stretch {
    align-items: stretch;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-stretch {
    justify-content: stretch;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.white-space-nowrap {
    white-space: nowrap;
}

.font-bold {
    font-weight: 700;
}

.overflow-hidden {
    overflow: hidden;
}
