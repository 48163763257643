.card {
    height: 100%;
}

.cardTitle {
    display: flex;
    align-items: center;
    gap: 8px;
}

.cardTitleText {
    margin-left: 0.5rem;
    font-weight: 400;
}

.list {
    display: flex;
    gap: 8px;
    flex-grow: 1;
    justify-content: stretch;
}

.list > * {
    flex-grow: 1;
}

.list td {
    border: none !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    border-radius: 5px;
}
